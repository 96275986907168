import React, { useState, useContext } from "react"
import styled from "styled-components"
import ReactPhoneInput from "react-phone-number-input/basic-input"
import PropTypes from "prop-types"

import Form from "src/components/Form"
import checkIcon from "src/componentsV2/Plans/images/icons/check.svg"
import LoadingIcon from "src/componentsV2/LoadingIcon"
import validateEmail from "src/utils/validateEmail"
import { FormContext } from "src/components/Form"
import { actionTypes } from "src/components/Form/reducer"

export const ErrorMessage = styled.div`
  color: tomato;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const Input: any = styled(
  ({ className, error, dirty, validating, disabled, ...props }) => {
    return (
      <div
        className={`
        ${className}
        ${!!error ? "has-error" : ""}
        ${!error && dirty ? "has-valid" : ""}
        ${validating ? "validating" : ""}
        ${disabled ? "disabled" : ""}
      `}
      >
        <input {...props} disabled={disabled} />
        <LoadingIcon className="loading-icon" />
        <img src={checkIcon} />
        <ErrorMessage>{error}</ErrorMessage>
      </div>
    )
  }
)`
  position: relative;
  input {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 15px;
    color: #0f1722;
    border-radius: 4px;
    border: 1px solid #d1d1d1;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
    outline: none;

    ::placeholder {
      color: #a5abb4;
      opacity: 1;
    }

    :focus {
      border: 2px solid #1283ff;
      color: #0f1722;
    }
  }

  img {
    display: none;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.has-error {
    input {
      border-color: tomato;
    }
  }

  &.has-valid,
  &.disabled {
    img {
      display: block;
    }
    input {
      border: 2px solid #489091;
      color: #489091;
    }
  }

  .loading-icon {
    display: none;
    position: absolute;
    right: 15px;
    top: 13px;
  }

  &.validating {
    &.has-valid {
      img {
        display: none;
      }
    }
    .loading-icon {
      display: block;
    }
  }
`

Input.propTypes = {
  error: PropTypes.string,
}

Input.defaultProps = {
  error: undefined,
}

Input.validate = (value: string) =>
  !value || !value.trim() ? "This field is required." : undefined

const _EmailInput: any = ({
  className,
  error,
  dirty,
  validating,
  disabled,
  ...props
}: any) => {
  const { dispatch } = useContext<any>(FormContext)
  const [isValid, setIsValid] = useState(false)

  const validate = async (value: string) => {
    if (!value || !validateEmail(value)) {
      setIsValid(false)
      dispatch({
        type: actionTypes.FORM_FIELD_ERROR,
        formName: props.formName,
        fieldName: props.name || "email",
        error: "Please enter a valid company email address.",
      })
      return
    }

    dispatch({
      type: actionTypes.FORM_FIELD_ERROR,
      formName: props.formName,
      fieldName: props.name || "email",
      error: null,
    })
    setIsValid(true)
  }

  return (
    <div
      className={`
        ${className}
        ${!!error ? "has-error" : ""}
        ${!error && isValid ? "has-valid" : ""}
        ${disabled ? "disabled" : ""}
      `}
    >
      <input
        {...props}
        disabled={disabled}
        onBlur={(e: any) => validate(e.target.value)}
      />
      <img src={checkIcon} />
      <ErrorMessage>{error}</ErrorMessage>
    </div>
  )
}

export const EmailInput = Input.withComponent(_EmailInput)

const _PhoneInput = ({ className, error, dirty, disabled, ...props }: any) => {
  return (
    <div
      className={`
        ${className}
        ${error ? "has-error" : ""}
        ${!error && dirty && props.value ? "has-valid" : ""}
        ${disabled ? "disabled" : ""}
      `}
    >
      <ReactPhoneInput {...props} disabled={disabled} />
      <img src={checkIcon} />
      <ErrorMessage>{error}</ErrorMessage>
    </div>
  )
}

_PhoneInput.validate = (value: string) =>
  value ? undefined : "This field is required."

export const PhoneInput = Input.withComponent(_PhoneInput)

const Label = styled.div`
  color: #0f1722;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 5px;
  &.contact-sales-title {
    font-weight: 400;
    color: #0e282d;
  }
`

export const FormField = (props: any) => {
  const requiredSign = props["required-sign"]

  return (
    <div className="mb-6">
      <Label className={`${props.className}`}>
        {props.title}
        {requiredSign && <span className="text-blue"> {requiredSign}</span>}
        {props.description && (
          <span className="text-gray-3"> {props.description}</span>
        )}
      </Label>
      <Form.Field {...props} />
    </div>
  )
}
