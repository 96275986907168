import React from "react"

import styled from "styled-components"

const IconWrapper = styled.div`
  display: inline-block;
  position: relative;
  height: 15px;
  width: 15px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #489090 transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export default ({ className }: any) => (
  <IconWrapper className={`${className || ""}`}>
    <div />
    <div />
    <div />
    <div />
  </IconWrapper>
)
