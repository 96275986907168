const invalidDomains = [
  // Main email providers
  "@yahoo.",
  "@hotmail.",
  "@live.",
  "@aol.",
  "@msn.",
  "@outlook.",
  "@gmail.",
  // Protonmail domains
  "@proton.me",
  "@protonmail.com",
  "@pm.me",
  "@protonmail.ch",
  // Tutanota domains
  "@tutanota.com",
  "@tutanota.de",
  "@keemail.me",
  "tuta.io",
  "tutamail.com",
  // Posteo domains
  "@posteo.",
  // Test email providers
  "test."
]

const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

function validateValidEmailDomain(email: string): boolean {
  return !invalidDomains.some(domain => email.includes(domain))
}

export default (email: string) => {
  const lowerCaseEmail = String(email).toLowerCase()

  return regex.test(lowerCaseEmail) && validateValidEmailDomain(lowerCaseEmail)
}
