export default (e: any) => {
  let error =
    e.networkError &&
    e.networkError.result &&
    e.networkError.result.errors.slice(0, 1)

  if (error) {
    error = error.length ? error[0] : error
    error = typeof error === "object" ? error.msg : error
  } else {
    error = e.toString()
  }

  return error
}
