import styled from "styled-components"

export const Button = styled.button`
  font-weight: bold;
  font-size: 0.875rem;
  border-radius: 25px;
  padding: 12px 23px;
  outline: 0px;
  border-width: 1.5px;
  border-style: solid;
  border-image: initial;

  :focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.6;
  }
`

export const ButtonBlue = styled(Button)`
  color: rgb(255, 255, 255);
  cursor: pointer;
  background: rgb(16, 122, 241);
  border-color: rgb(16, 122, 241);
`

export const ButtonOutLineBlue = styled(Button)`
  color: rgb(15, 23, 34);
  cursor: pointer;
  background: white;
  border-color: rgb(16, 122, 241);
`
